import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';

import { PAGES, STATES } from 'config';
import { FORMAT_GRAPH_DATA, isMobileScreen } from 'utilities';
import { Layout } from 'containers';
import { Banner, Breadcrumbs, ActivityCard } from 'components';
import SVGPagerArrowLeft from '../../../public/svg/pager-arrow-left.svg';
import SVGPagerArrowRight from '../../../public/svg/pager-arrow-right.svg';

const showdown = require('showdown');

const converter = new showdown.Converter();

class TourDay extends PureComponent {
  /**
   * Get the pager
   */
  getPager() {
    const { pageContext } = this.props;
    let anchor = '#tour-day';
    if (typeof window !== 'undefined' && isMobileScreen(window)) anchor = '';

    return (
      <div className="l-tour-day__pager">
        {pageContext.prev_absolute_slug && (
          <Link
            to={`${pageContext.prev_absolute_slug}${anchor}`}
            className="c-button c-button--border-alt l-tour-day__pager-prev"
          >
            <div>
              <SVGPagerArrowLeft />
            </div>
          </Link>
        )}

        {pageContext.next_absolute_slug && (
          <Link
            to={`${pageContext.next_absolute_slug}${anchor}`}
            className="c-button c-button--border-alt l-tour-day__pager-next"
          >
            {`Day ${pageContext.next_ordinal_number}`}
            <SVGPagerArrowRight />
          </Link>
        )}
      </div>
    );
  }


  /**
   * Get the summary list
   */
  getSummaryList() {
    const { pageContext, data } = this.props;

    let summaryList = FORMAT_GRAPH_DATA(data.allTourPages);

    if (pageContext.reverse_tour_id) {
      summaryList = summaryList.find(tour => tour.api_id === pageContext.reverse_tour_id);
      summaryList = summaryList ? summaryList.reverse_days : null;
    } else {
      summaryList = summaryList.find(tour => tour.api_id === pageContext.tour_id);
      summaryList = summaryList ? summaryList.days : null;
    }

    return summaryList;
  }


  /**
   * Render
   */
  render() {
    const { pageContext, data } = this.props;

    const Pager = this.getPager();
    const summaryList = this.getSummaryList();

    const activityList = FORMAT_GRAPH_DATA(data.allActivityPages).filter(item => item.day_ids.includes(pageContext.api_id));

    const pageTitle = `${pageContext.location_start}${pageContext.location_end ? ` to ${pageContext.location_end}` : ''}`;

    const breadcrumbs = [
      {
        name: PAGES.TOURS.TITLE,
        absolute_slug: PAGES.TOURS.PATH,
      },
      {
        name: pageContext.parent_parent_name,
        absolute_slug: pageContext.parent_parent_absolute_slug,
      },
      {
        name: pageContext.parent_name,
        absolute_slug: pageContext.parent_absolute_slug,
      },
      {
        name: pageTitle,
        absolute_slug: pageContext.absolute_slug,
      },
    ];

    return (
      <Layout page="tour-day">
        <Helmet
          title={pageContext.page_title}
          meta={[
            { name: 'description', content: pageContext.meta_description },
            { name: 'og:title', content: pageContext.og_title },
            { name: 'og:description', content: pageContext.og_description },
          ]}
          link={[{ rel: 'canonical', href: `${pageContext.absolute_slug}/` }]}
        />

        <Banner backgroundImage={`${pageContext.image_url}?h=590&fit=crop&auto=format`} />

        <section className="l-tour-day" id="tour-day">
          <div className="l-container">
            <Breadcrumbs list={breadcrumbs} />

            <div className="l-tour-day__main">
              <div className="l-tour-day__header">
                <h1 className="c-heading c-heading--h1">
                  <span>
                    {`Day ${pageContext.ordinal_number}: `}
                  </span>
                  {pageTitle}
                </h1>

                {Pager}
              </div>

              <div className="l-tour-day__grid">

                <div className="l-tour-day__description">
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.description_markdown) }} />

                  {Pager}
                </div>

                {summaryList && (
                  <div className="l-tour-day__summary">
                    <Link to={pageContext.parent_absolute_slug}>Summary</Link>
                    <ul>
                      {summaryList.map(day => (
                        <li key={day.id}>
                          <Link
                            to={day.absolute_slug}
                            className={day.api_id === pageContext.api_id ? STATES.ACTIVE : ''}
                          >
                            {`Day ${day.ordinal_number}: ${day.location_start}`}
                            {day.location_end ? ` to ${day.location_end}` : null}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <aside className="l-tour-day__side">
                  {!!activityList.length && (
                    <>
                      <p>Optional activities you can add to this day at checkout:</p>

                      <div className="l-tour-day__side-grid">
                        {activityList.map(item => (
                          <ActivityCard
                            key={item.id}
                            data={item}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </aside>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

TourDay.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      filter: {
                hidden_category: { eq: false },
              }
    ) {
      edges {
        node {
          id
          api_id
          days {
            id
            api_id
            tour_id
            reverse_tour_id
            location_start
            location_end
            ordinal_number
            absolute_slug
          }
          reverse_days {
            id
            api_id
            tour_id
            reverse_tour_id
            location_start
            location_end
            ordinal_number
            absolute_slug
          }
        }
      }
    }

    allActivityPages(
      filter: {
        guide_app_only: { ne: true },
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          absolute_slug
          destination_name
          sliding_first_picture_id
          day_ids
          options {
            price
            hidden
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }
  }
`;

export default TourDay;
